import styled from "styled-components"
import media from "styled-media-query"

export const PostHeader = styled.header`
    color: var(--postColor);
    margin: auto;
    max-width: 70rem;
    padding: 5rem 5rem 0;

    ${media.lessThan("large")`
        padding: 3rem 0 0;
        max-width: 100%;
    `}
`

export const PostTitle = styled.h1`
    font-size: 4rem;
    font-weight: 700;
    padding: 0 1.4rem;
    margin: 1rem auto;

    ${media.lessThan("large")`
        font-size: 2.8rem;
        line-height: 1.1;
        padding: 0 1rem;
    `}
`

export const PostDescription = styled.h2`
    font-size: 2rem;
    font-weight: 200;
    padding: 0 1.4rem;

    ${media.lessThan("large")`
        font-size: 1.6rem;
        line-height: 1.3;
        padding: 0 1rem;
    `}
`

export const PostDate = styled.p`
    font-size: 1.1rem;
    font-weight: 100;
    padding: 0 1.4rem;
    ${media.lessThan("large")`
        padding: 0 1rem;
    `}
`

export const MainContent = styled.section`
    margin: auto;
    max-width: 70rem;
    padding: 2rem 5rem;

    ${media.lessThan("large")`
        padding: 2rem 0;
        max-width: 100%;
    `}

    p,
    h1,
    h2,
    h3,
    h4,
    ul,
    ol,
    .tags,
    iframe,
    .button-post {
        color: var(--postColor);
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.7;
        letter-spacing: 0.069rem;
        padding: 0 1.4rem;

        ${media.lessThan("large")`
            padding: 0 1rem;
            word-break: break-word;
    `}
    }

    p {
        margin: 0 auto 1.6rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 2.4rem auto 1rem;
        ${media.lessThan("large")`
            margin: 1rem auto;
        `}
    }

    ul,
    ol {
        padding-left: 2.5rem;
        margin: 0 auto 1.6rem;
    }
    ol {
        list-style: decimal;
    }
    ul {
        list-style: disc;
    }
    li {
        padding: 0.625rem 0;

        & > ul {
            margin-bottom: 0;
        }
    }

    p,
    li {
        code {
            word-wrap: break-word;
        }
    }
    sup {
        top: -.5em;
    }
    sub, sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    img {
        display: block;
        max-width: 100%;
        margin: 1.875rem auto;
    }

    iframe {
        padding: 0 1.6rem 1.6rem;
        width: 100%;
        ${media.lessThan("large")`
            padding: 0 1rem;
    `}
    }

    blockquote {
        color: var(--postColor);
        border-left: 0.3rem solid var(--highlight);
        padding: 0 1.875rem;
        margin: 3.125rem auto;
        ${media.lessThan("large")`
            margin-left: 1rem;
    `}
    }

    hr {
        border: 1px solid var(--borders);
        margin: 3rem auto;
    }

    #twitter-widget-0,
    .instagram-media,
    .twitter-tweet {
        margin: 20px auto !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: 800;
        letter-spacing: 0.069rem;
        line-height: 1.4;
    }

    h1 {
        font-size: 2.8rem;
        ${media.lessThan("large")`
            font-size: 1.875rem;
    `}
    }

    h2 {
        font-size: 2.1rem;
        ${media.lessThan("large")`
            font-size: 1.375rem;
    `}
    }

    h3 {
        font-size: 1.6rem;
        ${media.lessThan("large")`
            font-size: 1.125rem;
    `}
    }

    h4 {
        font-size: 1.4rem;

        ${media.lessThan("large")`
            font-size: 1.120rem;
    `}
    }

    h5 {
        font-size: 1.2rem;

        ${media.lessThan("large")`
            font-size: 1.115rem;
    `}
    }

    strong {
        font-weight: 700;
    }

    .gatsby-resp-image-background-image {
        z-index: 2;
        opacity: 1 !important;
    }

    .gatsby-resp-image-image {
        box-shadow: none !important;
        transition: opacity 0.2s;

        &.lazyload {
            opacity: 0;
        }

        &.lazyloaded {
            opacity: 1;
            z-index: 3;
        }
    }
    .gatsby-resp-image-figcaption {
        text-align: center;
        color: var(--postColor);
        font-weight: 300;
        letter-spacing: 0.05rem;
    }
    .gatsby-highlight {
        padding: 0 1.6rem 1.6rem;
        ${media.lessThan("large")`
            padding: 0;
    `}
    }

    .instagram-media {
        margin: 1rem auto !important;
    }

    a {
        color: var(--highlight);
        text-decoration: none;
        transition: opacity 0.5s;

        svg {
            color: var(--postColor);
        }

        &:hover {
            opacity: 0.8;
        }
    }
    em {
        font-style: italic;
      }


`